'use strict';

// utility class
import ApplyClass from './Utils/ApplyClass';
import Common from './Utils/Common';
import ControllerObject from './Controller/Controller';

//import DeleteCover from './View/DeleteCover';
import SmoothScroll from './View/SmoothScroll';
import Drawer from './View/Drawer';
import GoTop from './View/GoTop';

$(() => {
	const Controller = new ControllerObject();
	Common.addHtmlClass();
	//カバー削除
	// $('#cover').each((idx, e) => {
	// 	const $e = $(e);
	// 	ApplyClass.apply(DeleteCover, $e, [$e, Controller]);
	// });
	//スムーススクロール
	$('a[href^="#"]').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(SmoothScroll, $e, [$e, Controller]);
	});
	// スマホメニュー
	$('.btn--sp-menu').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(Drawer, $e, [$e, Controller]);
	});
	// TOPに戻るボタン
	$('.anchor--go-top').each((idx, e) => {
		const $e = $(e);
		ApplyClass.apply(GoTop, $e, [$e, Controller]);
	});
});

