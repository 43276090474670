'use strict';

/**
 * @classname GoTop
 * @classdesc TOPに戻る
 */
export default class GoTop {
	/**
	 * @constructor
	 * @param {jQuery} $e - .anchor--go-top
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$e = $e;
		$(window).on('scroll', () => this.onScroll());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof GoTop
	 * @return undefined
	 */
	onScroll() {
		const windowHeight = $(window).innerHeight();
		const scroll = window.scrollY;
		console.log(windowHeight, scroll);
		if(scroll > windowHeight) {
			this._$e.addClass('show');
		} else {
			this._$e.removeClass('show');
		}
	}
};
