/**
 * @file Controller
 */

'use strict';
import Listener from '../Utils/Listener';

let instance = null;

/**
 * @classname Controller
 * @classdesc Controller
 */
export default class Controller {

	/**
	 * @constructor
	 */
	constructor() {
		if (instance) {
			return instance;
		}
		console.log('Controller.constructor');

		this._listener = new Listener();


		instance = this;
		return instance;
	};


	/**
	 * listener on
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	on(name, fn) {
		this._listener.on(name, fn);
	};


	/**
	 * listener off
	 *
	 * @memberof Basic
	 * @param {string} name - イベント名
	 * @param {function} fn - 実行する関数
	 * @return {undefined}
	 */
	off(name, fn) {
		this._listener.off(name, fn);
	};
}
