'use strict';

/**
 * @classname Drawer
 * @classdesc ドロワーメニュー
 */
export default class Drawer {
	/**
	 * @constructor
	 * @param {jQuery} $e - .btn--sp-menu
	 */
	constructor($e) {
		console.log(`${this.constructor.name}.constructor`);
		this._$drawer = $('#drawer');
		this._$drawerBg = $('.drawer-bg');
		this._$closeBtn = $('.btn--drawer-close');
		$e.on('click', () => this.onClick());
		this._$closeBtn.on('click', () => this.drawerClose());
		this._$drawerBg.on('click', () => this.drawerClose());
		$('.list--drawer-menu .anchor').on('click', () => this.drawerClose());
	};


	/**
	 * クリックのコールバック
	 *
	 * @memberof Drawer
	 * @return undefined
	 */
	onClick() {
		this._$drawerBg.addClass('show');
		this._$drawer.addClass('show');
	}


	/**
	 * ドロワーを閉じる
	 *
	 * @memberof Drawer
	 * @return undefined
	 */
	drawerClose() {
		this._$drawerBg.removeClass('show');
		this._$drawer.removeClass('show');
	}
};
